const header = document.querySelector('.header');
const hamburgerBtn = document.querySelector('.hamburger');
const navMobile = document.querySelector('.nav--mobile');
const accordion = document.querySelector('.accordion');
const accordionBtns = document.querySelectorAll('.accordion__btn');

const modalForm = document.querySelector('.modal__form');
const nameInput = document.querySelector('#name');
const emailInput = document.querySelector('#email');
const pageviewsSelect = document.querySelector('#pageviews');
const websiteInput = document.querySelector('#website');
const msgTextarea = document.querySelector('#msg');
const formConsentCheckbox = document.querySelector('#form-consent');
const nlFormConsentCheckbox = document.querySelector('#nl-form-consent');
const nlConsentCheckbox = document.querySelector('#nl-consent');
const newsletterInput = document.querySelector('#newsletter');
const formLoadTimeInput = document.querySelector('#form-lt');

const formSubmitBtn = document.querySelector('.form__btn--submit');
const formCancelBtn = document.querySelector('.form__btn--cancel');
const subscribeBtn = document.querySelector('.subscribe-btn');

let NL_API_KEY;

const mobileMenuOpenHandler = () => {
	document.body.classList.toggle('no-scroll');
	navMobile.classList.toggle('active');
	hamburgerBtn.classList.toggle('active');
};

const headerBgChange = () => {
	if (window.scrollY > 10) {
		header.classList.add('white-bg');
	} else {
		header.classList.remove('white-bg');
	}
};

const showError = (input, msg) => {
	const formBox = input.closest('.form__box');
	const errorMsg = formBox.querySelector('.error-text');

	formBox.classList.add('error');
	errorMsg.textContent = msg;
};

const clearError = input => {
	const formBox = input.closest('.form__box');
	formBox.classList.remove('error');
};

const checkForm = input => {
	input.forEach(item => {
		if (item.value === '') {
			showError(item, item.placeholder);
		} else {
			clearError(item);
		}
	});
};

const checkMail = email => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (re.test(email.value)) {
		clearError(email);
	} else {
		showError(email, 'Enter valid e-mail address');
	}
};

const checkCheckbox = checkbox => {
	if (checkbox.checked == true) {
		clearError(checkbox);
	} else {
		showError(checkbox, 'Check the consent checkbox');
	}
};

const checkSelect = select => {
	if (select.selectedIndex != 0) {
		clearError(select);
	} else {
		showError(select, 'Select option');
	}
};

const checkErrors = input => {
	let errorCount = 0;

	input.forEach(item => {
		const formBox = item.closest('.form__box');
		if (formBox.classList.contains('error')) {
			errorCount++;
			return;
		}
	});

	if (errorCount === 0) {
		return true;
	}
};

const clearInputs = inputs => {
	inputs.forEach(input => {
		input.value = '';
	});
};

const clearSelects = selects => {
	selects.forEach(select => (select.selectedIndex = 0));
};

const clearCheckboxes = checkboxes => {
	checkboxes.forEach(checkbox => (checkbox.checked = false));
};

function openAccordionItems() {
	if (this.parentElement.nextElementSibling.classList.contains('active')) {
		this.parentElement.nextElementSibling.classList.remove('active');
		this.classList.remove('active');
	} else {
		closeAccordionItems();
		this.parentElement.nextElementSibling.classList.toggle('active');
		this.classList.toggle('active');
	}
}

const closeAccordionItems = () => {
	const allActiveItems = document.querySelectorAll('.accordion__info');
	allActiveItems.forEach(item => item.classList.remove('active'));
	accordionBtns.forEach(btn => btn.classList.remove('active'));
};

const fetchMlApiKey = async () => {
	try {
		const response = await fetch('https://yieldbird.com/mailerlite_api_key.php?get_key=true');
		const data = await response.json();
		NL_API_KEY = data.api_key;
	} catch (error) {
		console.error('Error fetching API key:', error);
	}
};

const nlSubscribe = (input, redirect) => {
	fetchMlApiKey().then(() => {
		const emailData = {
			email: input.value,
		};

		fetch('https://connect.mailerlite.com/api/subscribers', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${NL_API_KEY}`,
			},
			body: JSON.stringify(emailData),
		})
			.then(res => {
				if (res.ok && redirect) {
					window.location.replace('thank-you-page-2');
				} else if (res.ok) {
					console.log('subscribed to nl');
				} else {
					throw new Error('Something went wrong!');
				}
			})
			.catch(err => {
				showError(newsletterInput, err.message);
			});
	});
};

newsletterInput.addEventListener('keyup', () => {
	checkMail(newsletterInput);
	checkCheckbox(nlConsentCheckbox);
	checkErrors([newsletterInput, nlConsentCheckbox]);
});

subscribeBtn.addEventListener('click', e => {
	e.preventDefault();

	checkMail(newsletterInput);
	checkCheckbox(nlConsentCheckbox);
	if (checkErrors([newsletterInput, nlConsentCheckbox])) {
		nlSubscribe(newsletterInput, true);
	}
});

if (modalForm) {
	modalForm.addEventListener('submit', e => {
		e.preventDefault();

		let noFromErrors = false;

		checkForm([nameInput, emailInput, websiteInput, msgTextarea]);
		checkMail(emailInput);
		checkCheckbox(formConsentCheckbox);

		if (pageviewsSelect) {
			checkSelect(pageviewsSelect);
			noFromErrors = checkErrors([nameInput, emailInput, pageviewsSelect, formConsentCheckbox, websiteInput, msgTextarea]);
		} else {
			noFromErrors = checkErrors([nameInput, emailInput, formConsentCheckbox, websiteInput, msgTextarea])
		}

		if (noFromErrors) {
			const currentTime = new Date().getTime();
			const formLoadTime = parseInt(formLoadTimeInput.value);
			const timeDifference = currentTime - formLoadTime;
			const minTimeThreshold = 4000;

			if (nlFormConsentCheckbox.checked == true && timeDifference > minTimeThreshold) {
				nlSubscribe(emailInput, false);
				modalForm.submit();
			} else if (timeDifference > minTimeThreshold) {
				modalForm.submit();
			} else {
				alert('Please take your time to fill out the form and submit again.');
			}
		}
	});
}

accordionBtns.forEach(btn => btn.addEventListener('click', openAccordionItems));
window.addEventListener('scroll', headerBgChange);
hamburgerBtn.addEventListener('click', mobileMenuOpenHandler);
window.addEventListener('load', () => {
	const formLoadTime = new Date().getTime();
	if (formLoadTimeInput) {
		formLoadTimeInput.value = formLoadTime;
	}
});
